import React from "react";
import Helmet from "react-helmet";

import Header from "../components/header/init";

import "./styles/init.scss";

import "prismjs/themes/prism.css";

var BASE_URL = "";

export default ({ children, location }) => (
  <div style={{}}>
    <Header />
    {children}
  </div>
);
