import React from "react";
import { Link } from "gatsby";

import "./style.scss";

class Header extends React.Component {
  constructor() {
    super();
    this.displayName = "Header";
    this.state = {
      navOpen: false,
    };
    this.handleNavToggleClick = this.handleNavToggleClick.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }
  handleNavToggleClick() {
    this.setState({ navOpen: !this.state.navOpen });
  }
  closeNav() {
    this.setState({ navOpen: false });
  }

  render() {
    const navClass = this.state.navOpen ? "nav-active" : "";

    return (
      <div id="header">
        <h1 className="eta">
          <Link to="/" onClick={this.closeNav}>
            Andrew Mahon
          </Link>
        </h1>
        <nav className={navClass}>
          <div className="nav-toggle-wrap">
            <a className="nav-toggle" onClick={this.handleNavToggleClick}>
              <span>toggle menu</span>
            </a>
          </div>
          <ul className="items">
            <li>
              <Link to="/about/" onClick={this.closeNav}>
                About
              </Link>
            </li>
            <li>
              <Link to="/blog/" onClick={this.closeNav}>
                Blog
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Header;
